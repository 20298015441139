<template>
  <v-footer
    height="88"
    class="justify-center primary"
    :style="{
      // borderTop: 'medium solid !important',
      // borderTopColor: $vuetify.theme.currentTheme.anchor + ' !important'
    }"
  >
    <social-media />
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',

    components: {
      SocialMedia: () => import('@/components/SocialMedia'),
    },
  }
</script>
